import * as React from "react";
import { observer } from "mobx-react";
import { AppStateObserver, injectAppState } from "../../stores";
import "./Applications.css";
import { differenceInCalendarDays, format } from "date-fns";
import { Trans } from "react-i18next";
import localeChanger from "../../helpers/localeChanger";
import i18next from "i18next";
import Load from "../Load";
import i18n from "i18next";
import { toast } from "react-toastify";
import { applicationByRevisionDateStatuses } from "consts";

import {
  BsCheckCircle,
  BsArrowClockwise,
  BsPencil,
  BsXCircle,
  Bs5Circle,
  BsEye,
  BsChatDots,
  BsClock,
  BsQuestionCircle,
  BsArrowRight,
  BsInfoCircle,
} from "react-icons/bs";
import NotifyTeamMembersButton from "components/NotifyTeamMembersButton/NotifyTeamMembersButton";

import { Accordion, Card, Button } from "react-bootstrap";
import Notify from "../../helpers/Notifier";

export interface ApplicationsProps {}

export interface ApplicationsState {}

@injectAppState
@observer
class Applications extends React.Component<
  AppStateObserver,
  ApplicationsProps,
  ApplicationsState
> {
  state = {
    availableComps: [],
    VisibleCurrent: false,
    VisibleHistory: false,
  };

  constructor(props) {
    super(props);
  }

  toggleListCurrent = () => {
    this.setState({
      VisibleCurrent: !this.state.VisibleCurrent,
    });
  };

  toggleListHistory = () => {
    this.setState({
      VisibleHistory: !this.state.VisibleHistory,
    });
  };

  componentDidMount() {
    this.props.appState.applicationsStore.loadEntities();
    this.props.appState.competitionsStore.loadCompetitions().then(res => {
      this.setState({ availableComps: JSON.parse(JSON.stringify(res)) });
    });

    const { applicantsStore, applicationsStore } = this.props.appState;
    const applicantId = applicantsStore.applicant.id;

    if (applicantId) {
      applicationsStore.getCurrentApplications(applicantId);
      applicationsStore.getHistoryApplications(applicantId);
    }

    this.toggleListCurrent();
  }

  render() {
    return <React.Fragment>{this.renderApplications()}</React.Fragment>;
  }

  renderApplicationStatus(status) {
    if (status === "PASSED") {
      return (
        <span className="text__success" style={{ fontSize: "14px" }}>
          <BsCheckCircle color="#007E6D" /> {i18next.t(status)}
          &nbsp;
        </span>
      );
    }

    if (status === "REFUSED_BY_APPLICANT") {
      return (
        <span className="text__black" style={{ fontSize: "14px" }}>
          <BsArrowClockwise color="black" /> {i18next.t("Withdrawn")}
          &nbsp;
        </span>
      );
    }

    if (status === "PENDING") {
      return (
        <span className="text__black" style={{ fontSize: "14px" }}>
          <BsPencil color="black" /> {i18next.t(status)}
          &nbsp;
        </span>
      );
    }

    if (status === "ACCEPTED") {
      return (
        <span className="text__success" style={{ fontSize: "14px" }}>
          <BsCheckCircle color="#007E6D" /> {i18next.t(status)}
          &nbsp;
        </span>
      );
    }

    if (status === "REFUSED") {
      return (
        <span className="text__failure" style={{ fontSize: "14px" }}>
          <BsXCircle color="#DC3545" /> {i18next.t(status)}
          &nbsp;
        </span>
      );
    }

    if (status === "EVALUATION") {
      return (
        <span className="text__orange" style={{ fontSize: "14px" }}>
          <Bs5Circle color="#DE944C" /> {i18next.t(status)}
          &nbsp;
        </span>
      );
    }

    if (status === "VOTING") {
      return (
        <span className="text__orange" style={{ fontSize: "14px" }}>
          <BsEye color="#DE944C" /> {i18next.t(status)}
          &nbsp;
        </span>
      );
    }

    if (status === "INTERVIEW") {
      return (
        <span className="text__orange" style={{ fontSize: "14px" }}>
          <BsChatDots color="#DE944C" /> {i18next.t(status)}
          &nbsp;
        </span>
      );
    }

    if (status === "REWORK_DOCS" || status === "PENDING_AFTER_REWORK") {
      return (
        <span className="text__orange" style={{ fontSize: "14px" }}>
          <BsClock color="#DE944C" /> {i18next.t(status)}
          &nbsp;
        </span>
      );
    }

    if (status === "ADD_DOCS_NOT_OBTAINED") {
      return (
        <span className="text__failure" style={{ fontSize: "14px" }}>
          <BsQuestionCircle color="#DC3545" /> {i18next.t(status)}
          &nbsp;
        </span>
      );
    }

    if (status === "FINAL_REVIEW") {
      return (
        <span className="text__orange" style={{ fontSize: "14px" }}>
          <BsQuestionCircle color="#DE944C" /> {i18next.t(status)}
          &nbsp;
        </span>
      );
    }

    if (status === "NOT_PASSED") {
      return (
        <span className="text__failure" style={{ fontSize: "14px" }}>
          <BsXCircle color="#DC3545" /> {i18next.t(status)}
          &nbsp;
        </span>
      );
    }

    if (status === "COMPLETED") {
      return (
        <span className="text__black" style={{ fontSize: "14px" }}>
          <BsCheckCircle color="black" /> {i18next.t(status)}
          &nbsp;
        </span>
      );
    }

    if (status === "REVIEW") {
      return (
        <span className="text__orange" style={{ fontSize: "14px" }}>
          <BsEye color="#DE944C" /> {i18next.t(status)}
          &nbsp;
        </span>
      );
    }

    if (status === "REWORK") {
      return (
        <span className="text__orange" style={{ fontSize: "14px" }}>
          <BsEye color="#DE944C" /> {i18next.t(status)}
          &nbsp;
        </span>
      );
    }

    return (
      <span className="text__orange" style={{ fontSize: "14px" }}>
        <BsClock color="#DE944C" /> {i18next.t(status)}
        &nbsp;
      </span>
    );
  }

  isDisabled = id => {
    if (this.state.availableComps) {
      for (let i in this.state.availableComps) {
        if (this.state.availableComps[i].applicationId === id) {
          return false;
        }
      }
    }
    return true;
  };

  alertLateSubmission = () => {
    Notify.pushErrorMessage(i18next.t("lateSubmissionError"));
  };

  checkRework = (application, isDisabled) => {
    if (application.applicationStatus === "REWORK" && isDisabled) {
      Notify.pushErrorMessage(i18next.t("lateSubmissionReworkError"));
    } else {
      const ROUTE = `/application/${application.id}/${application.competition.id}`;
      window.open(ROUTE);
    }
  };

  renderApplications() {

    // //TODO temp zaglushka
    // return <p className="portal__text">
    //   <Trans>NoApps</Trans>
    // </p>


    const {applicationList, isLoadingList, historyList, currentList} =
        this.props.appState.applicationsStore;
    const localeDate = localeChanger(i18n.language ? i18n.language : "ru");
    const isInRevisedDate =
        this.props.appState.competitionsStore?.competitions?.length > 0
            ? differenceInCalendarDays(
            new Date(
                this.props.appState.competitionsStore?.competitions[0]?.dateEndRevised
            ),
            new Date()
        ) >= 0
            : false;

    const {VisibleCurrent, VisibleHistory} = this.state;

    if (isLoadingList) {
      return (
          <div className="loader-container">
            <Load/>
          </div>
      );
    }

    if (!currentList || currentList.length === 0) {
      return null;
    }

    if (!historyList || historyList.length === 0) {
      return null;
    }

    if (!applicationList || applicationList.length === 0) {
      return (
          <p className="mt-3 NoApplications">
            <Trans>NoApplications</Trans>
          </p>
      );
    }

    return (
        <div className="container">

          <div style={{marginTop: "10px"}} className="accordionList-box">
            <h2
                className="accordionList-content-title"
                onClick={this.toggleListCurrent}
                style={{marginBottom: 0}}
            >
              <Trans>CurrentApplication</Trans>
              <svg
                  className={
                    this.state.VisibleCurrent
                        ? "rotate-icon rotate-icon-open"
                        : "rotate-icon"
                  }
                  width="14"
                  viewBox="0 0 320 512"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
              >
                <path
                    d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path>
              </svg>
            </h2>
            {this.state.VisibleCurrent && (
                <div className="accordionList-card">
                  {isLoadingList ? (
                      <div className="loader-container">
                        <Load/>
                      </div>
                  ) : (
                      currentList.map((application, idx) => {
                        return (
                            <div
                                className={`col my-application ${
                                    idx % 4 === 3 ? "break" : ""
                                }`}
                                key={idx}
                            >
                              <p className="CompetitionName">
                                <Trans>CompetitionName</Trans>
                              </p>
                              <p className="CompetitionName_text">
                                <span>{application.competition.name}</span>
                              </p>
                              <p className="portal__text">
                                <Trans>ApplicationNumber</Trans>
                              </p>
                              <p className="portal__pi__text">
                                <span>{application.serialNumber}</span>
                              </p>
                              <p className="portal__text">
                                <Trans>ApplyDate</Trans>
                              </p>
                              <p className="portal__pi__text">
                            <span>
                              {application.dateApply
                                  ? format(
                                      Date.parse(application.dateApply),
                                      "dd MMMM yyyy",
                                      {
                                        locale: localeDate,
                                      }
                                  )
                                  : format(
                                      Date.parse(application.dateRegistration),
                                      "dd MMMM yyyy",
                                      {
                                        locale: localeDate,
                                      }
                                  )}
                            </span>
                              </p>
                              <p className="portal__text">
                                <Trans>CompetitionType</Trans>
                              </p>

                              <p className="portal__pi__text">
                            <span>
                              {application.programs[0]?.name || (
                                  <Trans>NoCompetitionType</Trans>
                              )}
                            </span>
                              </p>
                              <p className="portal__text">
                                <Trans>Status</Trans>
                              </p>
                              <p className="portal__pi__text">
                                {this.renderApplicationStatus(
                                    application.applicationStatus
                                )}
                              </p>
                              {/* <p className="AboutStatuses-text">
                        <BsInfoCircle
                          style={{ marginRight: "5px", marginTop: "-5px" }}
                        />
                        <Trans>AboutStatuses</Trans>
                      </p> */}
                              {application.competition.status && (
                                  <>
                                    <p className="portal__text">
                                      <Trans>CompetitionStatus</Trans>
                                    </p>
                                    <p className="portal__pi__text">
                                <span>
                                  {this.renderApplicationStatus(
                                      application.competition.status
                                  )}
                                </span>
                                    </p>
                                  </>
                              )}

                              {isInRevisedDate &&
                                  applicationByRevisionDateStatuses.includes(
                                      application.applicationStatus
                                  ) && (
                                      <div
                                          style={{
                                            width: "50%",
                                            borderTop: "1px solid #a8a8a8",
                                            marginBottom: "20px",
                                          }}
                                      />
                                  )}

                              {this.isDisabled(application.id) &&
                              application.applicationStatus === "PENDING" ? (
                                  <div onClick={this.alertLateSubmission}>
                                    <button
                                        className="ViewAsMember-btn"
                                        style={{color: "#7a7a7a"}}
                                        disabled
                                    >
                                      <Trans>ViewAsMember</Trans>
                                      <BsArrowRight style={{width: "30px"}}/>
                                    </button>
                                  </div>
                              ) : (
                                  isInRevisedDate &&
                                  applicationByRevisionDateStatuses.includes(
                                      application.applicationStatus
                                  ) && (
                                      <div
                                          onClick={() => {
                                            this.checkRework(
                                                application,
                                                this.isDisabled(application.id)
                                            );
                                          }}
                                      >
                                        <button className="ViewAsMember-btn">
                                          {application.applicationStatus === "REWORK" ? (
                                              <Trans>ViewAsRework</Trans>
                                          ) : (
                                              <Trans>ViewAsMember</Trans>
                                          )}
                                          <BsArrowRight
                                              style={{width: "30px", marginLeft: "-3px"}}
                                          />
                                        </button>
                                      </div>
                                  )
                              )}

                              {isInRevisedDate &&
                                  applicationByRevisionDateStatuses.includes(
                                      application.applicationStatus
                                  ) &&
                                  application.applicationStatus !== "REWORK" &&
                                  application.applicationStatus !== "REFUSED" &&
                                  application.applicationStatus !==
                                  "REFUSED_BY_APPLICANT" &&
                                  application.applicationStatus !== "NOT_PASSED" && (
                                      <NotifyTeamMembersButton
                                          isInApplications
                                          appID={application.id}
                                      />
                                  )}
                            </div>
                        );
                      })
                  )}
                </div>
            )}
          </div>

          <div className="accordionList-box">
            <h2
                className="accordionList-content-title"
                onClick={this.toggleListHistory}
                style={{marginBottom: 0, marginTop: "20px"}}
            >
              <Trans>ApplicationHistory</Trans>
              <svg
                  className={
                    this.state.VisibleHistory
                        ? "rotate-icon rotate-icon-open"
                        : "rotate-icon"
                  }
                  width="14"
                  viewBox="0 0 320 512"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
              >
                <path
                    d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path>
              </svg>
              {" "}
            </h2>
            {this.state.VisibleHistory && (
                <div className="accordionList-card">
                  {isLoadingList ? (
                      <div className="loader-container">
                        <Load/>
                      </div>
                  ) : (
                      historyList.map((application, idx) => {
                        return (
                            <div
                                className={`col my-application ${
                                    idx % 4 === 3 ? "break" : ""
                                }`}
                                key={idx}
                            >
                              <p className="CompetitionName">
                                <Trans>CompetitionName</Trans>
                              </p>
                              <p className="CompetitionName_text">
                                <span>{application.competition.name}</span>
                              </p>
                              <p className="portal__text">
                                <Trans>ApplicationNumber</Trans>
                              </p>
                              <p className="portal__pi__text">
                                <span>{application.serialNumber}</span>
                              </p>
                              <p className="portal__text">
                                <Trans>ApplyDate</Trans>
                              </p>
                              <p className="portal__pi__text">
                            <span>
                              {application.dateApply
                                  ? format(
                                      Date.parse(application.dateApply),
                                      "dd MMMM yyyy",
                                      {
                                        locale: localeDate,
                                      }
                                  )
                                  : format(
                                      Date.parse(application.dateRegistration),
                                      "dd MMMM yyyy",
                                      {
                                        locale: localeDate,
                                      }
                                  )}
                            </span>
                              </p>
                              <p className="portal__text">
                                <Trans>CompetitionType</Trans>
                              </p>

                              <p className="portal__pi__text">
                            <span>
                              {application.programs[0]?.name || (
                                  <Trans>NoCompetitionType</Trans>
                              )}
                            </span>
                              </p>
                              <p className="portal__text">
                                <Trans>Status</Trans>
                              </p>
                              <p className="portal__pi__text">
                                {this.renderApplicationStatus(
                                    application.applicationStatus
                                )}
                              </p>
                              {/* <p className="AboutStatuses-text">
                        <BsInfoCircle
                          style={{ marginRight: "5px", marginTop: "-5px" }}
                        />
                        <Trans>AboutStatuses</Trans>
                      </p> */}
                              {application.competition.status && (
                                  <>
                                    <p className="portal__text">
                                      <Trans>CompetitionStatus</Trans>
                                    </p>
                                    <p className="portal__pi__text">
                                <span>
                                  {this.renderApplicationStatus(
                                      application.competition.status
                                  )}
                                </span>
                                    </p>
                                  </>
                              )}

                              {isInRevisedDate &&
                                  applicationByRevisionDateStatuses.includes(
                                      application.applicationStatus
                                  ) && (
                                      <div
                                          style={{
                                            width: "50%",
                                            borderTop: "1px solid #a8a8a8",
                                            marginBottom: "20px",
                                          }}
                                      />
                                  )}

                              {this.isDisabled(application.id) &&
                              application.applicationStatus === "PENDING" ? (
                                  <div onClick={this.alertLateSubmission}>
                                    <button
                                        className="ViewAsMember-btn"
                                        style={{color: "#7a7a7a"}}
                                        disabled
                                    >
                                      <Trans>ViewAsMember</Trans>
                                      <BsArrowRight style={{width: "30px"}}/>
                                    </button>
                                  </div>
                              ) : (
                                  isInRevisedDate &&
                                  applicationByRevisionDateStatuses.includes(
                                      application.applicationStatus
                                  ) && (
                                      <div
                                          onClick={() => {
                                            this.checkRework(
                                                application,
                                                this.isDisabled(application.id)
                                            );
                                          }}
                                      >
                                        <button className="ViewAsMember-btn">
                                          {application.applicationStatus === "REWORK" ? (
                                              <Trans>ViewAsRework</Trans>
                                          ) : (
                                              <Trans>ViewAsMember</Trans>
                                          )}
                                          <BsArrowRight style={{width: "30px"}}/>
                                        </button>
                                      </div>
                                  )
                              )}

                              {isInRevisedDate &&
                                  applicationByRevisionDateStatuses.includes(
                                      application.applicationStatus
                                  ) &&
                                  application.applicationStatus !== "REWORK" &&
                                  application.applicationStatus !== "REFUSED" &&
                                  application.applicationStatus !==
                                  "REFUSED_BY_APPLICANT" &&
                                  application.applicationStatus !== "NOT_PASSED" && (
                                      <NotifyTeamMembersButton
                                          isInApplications
                                          appID={application.id}
                                      />
                                  )}
                            </div>
                        );
                      })
                  )}
                </div>
            )}
          </div>
        </div>
    );
  }
}

export default Applications;
// <Link
//     to={`/application/${application.id}/${application.competition.id}`}
// >
