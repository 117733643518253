import React, { Fragment } from "react";
import { observer } from "mobx-react";
import { Trans } from "react-i18next";

import { AppStateObserver, injectAppState } from "stores";
import Competition from "components/Competition";
import SentApplicationView from "components/SentApplicationView";
import Load from "components/Load";
import "./MainPage.css";
import Popup from "reactjs-popup";
import { cubaREST } from "../../cubaREST";
import { differenceInCalendarDays } from "date-fns";
import { applicationByRevisionDateStatuses } from "../../consts";
import Notify from "../../helpers/Notifier";
export interface MainPageProps {}

export interface MainPageProps {}

export interface MainPageState {
  dateVotingEnd: string;
  showNotification: boolean;
  status: string;
}

@injectAppState
@observer
class MainPage extends React.Component<
  AppStateObserver,
  MainPageProps,
  MainPageState
> {
  state = {
    mounted: false,
    showModalNotification: false,
    dateVotingEnd: "",
    showNotification: false,
    status: "",
  };

  compare(a, b) {
    let comparision = 0;
    if (a.dateStartRegistration < b.dateStartRegistration) {
      comparision = 1;
    } else if (a.dateStartRegistration > b.dateStartRegistration) {
      comparision = -1;
    }
    return comparision;
  }

  async componentDidMount() {
    try {
      await this.props.appState.competitionsStore.loadCompetitions();
      await this.props.appState.competitionsStore.loadCompetitionTypes();
      await this.props.appState.applicationsStore.loadEntities();

      await this.props.appState.userStore.teamMemberOrTeamLead(
        this.props.appState.userStore.userInfo.id
      );
    } catch (e) {
      console.warn(e);
    }
    try {
      const response = await cubaREST.invokeService(
        "fsr_CompetitionService",
        "getParamsForExpertsNotification",
        null,
        { method: "GET" }
      );
      if (typeof response === "string") {
        const data = JSON.parse(response);
        const dateWithoutTime = data.dateVotingEnd.split(" ")[0];

        this.setState({
          dateVotingEnd: dateWithoutTime,
          showNotification: data.showNotification,
          status: data.status,
        });
      }
    } catch (e) {
      console.warn(e);
    }
  }

  render() {

    const { archivedCompetitions, isLoadingList, anketaOk, competitions } =
      this.props.appState.competitionsStore;
    const { language } = this.props.appState.userStore;
    const applicant = this.props.appState?.applicantsStore?.applicant;
    const applicantType = applicant?.applicantType
      ? applicant?.applicantType
      : "";
    const showCompsByRole =
      applicantType !== "GRADUATE_NIS" && applicantType !== "THIRD_PERSON";
    if (isLoadingList) {
      return (
        <div className="loader-container">
          <Load />
        </div>
      );
    }
    return (
      <div className="container" style={{ marginBottom: "60px" }}>
        <h1 className="title__home" style={{ marginBottom: "10px" }}>
          <Trans>Comps</Trans>
        </h1>
       
        
       
      
        <Popup
          open={this.props.appState.userStore.showConfirmModal}
          overlayStyle={{
            transition: "0.2s ease-in-out",
          }}
          closeOnDocumentClick={false}
        >
          <div className="modal">
            <div className="modal__header modal-title">
              <h1>
                <Trans>Notification</Trans>
              </h1>
            </div>
            <p
              style={{ textAlign: "center", fontSize: "12px", color: "black" }}
            >
              <Trans>FullFunctionOnDesktop</Trans>
            </p>
            <div className="btn__actions">
              <button
                className="button-input btn btn-success"
                onClick={() => {
                  this.props.appState.userStore.showConfirmModal = false;
                  this.props.appState.userStore.showReworkModal = true;
                }}
              >
                <Trans>Confirmed</Trans>
              </button>
            </div>
          </div>
        </Popup>
        {/* <Popup
          open={showNotification}
          overlayStyle={{
            transition: "0.2s ease-in-out",
          }}
          closeOnDocumentClick={false}
        >
          <div className="modal">
            <div className="modal__header modal-title">
              <h1>
                <Trans>Notification</Trans>
              </h1>
            </div>
            <p
              style={{ textAlign: "center", fontSize: "12px", color: "black" }}
            >
              {status === "success" ? (
                <Fragment>
                  <Trans>ReworkNotification</Trans> {dateVotingEnd}
                </Fragment>
              ) : null}
            </p>
            <div className="btn__actions">
              <button
                className="button-input btn btn-success"
                onClick={() => {
                  this.setState({ showNotification: false });
                }}
              >
                <Trans>Confirmed</Trans>
              </button>
            </div>
          </div>
        </Popup> */}
        <section className="compition-box">
          {showCompsByRole && competitions && competitions.length > 0 ? (
            this.renderAllCompetitions(competitions, anketaOk)
          ) : (
            <p className="portal__text">
              <Trans>NoComps</Trans>
            </p>
          )}
        </section>
        <section className="compition-box">
          {archivedCompetitions &&
            this.renderArchivedCompetitions(archivedCompetitions, anketaOk)}
        </section>
      </div>
    );
  }

  renderAllCompetitions = (comps, isItOk) => {
    const { isTeamMember, applicationId } =
      this.props.appState.userStore.teamRole;
    const applicationList =
      this.props.appState.applicationsStore?.applicationList;
    const applicationStatus = applicationList.length
      ? this.props.appState.applicationsStore?.applicationList[0]
          .applicationStatus
      : "";
    const applicationProgram = applicationList.length
      ? this.props.appState.applicationsStore?.applicationList[0].programs[0]
          ?.contractCode
      : "";
    let filteredCompetitions;
    if (applicationProgram === "SE") {
      if (applicationByRevisionDateStatuses.includes(applicationStatus)) {
        filteredCompetitions =
          this.props.appState.competitionsStore?.competitions?.filter(
            (comps) => {
              return (
                differenceInCalendarDays(
                  new Date(comps.dateEndRevised),
                  new Date()
                ) >= 0
              );
            }
          );
      } else {
        filteredCompetitions =
          this.props.appState.competitionsStore?.competitions?.filter(
            (comps) => {
              return (
                differenceInCalendarDays(
                  new Date(comps.dateEndRegistration),
                  new Date()
                ) >= 0
              );
            }
          );
      }
    } else {
      filteredCompetitions =
        this.props.appState.competitionsStore?.competitions?.filter((comps) => {
          return (
            differenceInCalendarDays(
              new Date(comps.dateEndRegistration),
              new Date()
            ) >= 0
          );
        });
    }

    if (!filteredCompetitions.length) {
      return (
        <p className="portal__text">
          <Trans>NoComps</Trans>
        </p>
      );
    }

    return filteredCompetitions.slice().map((competition) => {
      return (
        <Fragment key={competition.id}>
          {(competition.applicationId && isItOk) ||
          (isTeamMember && applicationId) ? (
            <SentApplicationView
              anketaOk={isItOk}
              key={competition.id}
              name={competition.name}
              dateStart={competition.dateStartRegistration}
              dateEnd={competition.dateEndRegistration}
              id={competition.id}
              status={competition.status}
              info={competition.info}
              isMainPage={true}
              appId={competition.applicationId ?? applicationId}
              applicationStatus={applicationStatus}
            />
          ) : (
            <Competition
              anketaOk={isItOk}
              key={competition.id}
              name={competition.name}
              dateStart={competition.dateStartRegistration}
              dateEnd={competition.dateEndRegistration}
              id={competition.id}
              status={competition.status}
              info={competition.info}
              isMainPage={true}
            />
          )}
        </Fragment>
      );
    });
  };

  renderArchivedCompetitions(archivedCompetitions, isItOk) {
    return archivedCompetitions
      .slice()
      .map((competition) => (
        <Competition
          anketaOk={isItOk}
          key={competition.id}
          name={competition.name}
          dateStart={competition.dateStartRegistration}
          dateEnd={competition.dateEndRegistration}
          id={competition.id}
          status={competition.status}
          info={competition.info}
          isMainPage={true}
        />
      ));
  }
}
export default MainPage;
